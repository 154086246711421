<template>
    <div class="select-group">
        <span style="padding:0">当前监测点预警状态：</span>
        <span v-for="(item, i) in tabs" :key="i" @click="tabClick(item, i)" class="flex-c" :class="{ 'active': i == index,['warning-'+i]:true }" v-if="warningStatus==item.codeValue">
            <svg-icon class="svgIcon" :icon-class="icons[i]"></svg-icon>
            <span>{{ item.codeDesc }}</span>
        </span>
    </div>
</template>
<script>
export default {
    name: "jWarningSelect",
    props: {
        value: [String, Number],
        tabs: [String, Array],
    },
    data() {
        return {
            index: 0,
            icons:['warning0','warning1','warning2','warning1','warning1','warning5'],
            warningStatus:"0",
        }
    },
    watch:{
        value:{
            handler(val){
                console.log(val,'valllll')
               this.warningStatus=val 
            },
            immediate: true,
        },
        
    },
    methods: {
        tabClick(item, i) {
            this.index = i
            this.$emit('input', item.codeValue)
            this.$emit('tab-click', item.codeValue)
        },
    }
}
</script>
<style scoped lang="scss">
.select-group {
    box-sizing: border-box;
    width: 100%;
    padding:5px 0;
    margin:10px 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .svgIcon{
        width:18px;
        height:18px;
        transform: translateY(0px);
    }
    > span {
        font-family: Alibaba PuHuiTi;
        border-radius:4px;
        font-size:14px;
        padding: 0 10px;
        line-height: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        ::v-deep path{
            fill:#707070;
        }
        &.warning-0{
            border-radius:4px;
            font-family:Alibaba PuHuiTi;
            color:#009443;
            font-size:14px;
        }
        &.warning-1{
            background:linear-gradient(180deg,#2f28f3 -91.3%,#288af3 247.83%);
            color:#ffffff;
        }
        &.warning-2{
            background:linear-gradient(180deg,#ffdd00 -53.33%,#fffd8d 133.33%);
            color:#a5672a;
        }
        &.warning-3{
            background:linear-gradient(180deg,#ff4800 -91.3%,#ffd400 247.83%);
            color:#ffffff;
        }
        &.warning-4{
            background:linear-gradient(180deg,#ff0000 -91.3%,#ffa4a4 247.83%);
            color:#ffffff;
        }
        &.warning-5{
            color:#aaaaaa;
            border-color:#aaaaaa;
        }
        &.active {
            border:1px solid;
            font-weight: bold;
        }
    }
}
.svg-icon{
    width: 20px;
    height: 20px;
}
// .flex-c {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
</style>