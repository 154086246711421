
import person from './children/person'
import device from './children/device'
import monitor from './children/monitor'
import base from './children/base'
import warning from './children/warning'
const routes = [
  ...base,
  person,
  device,
  monitor,
  warning,
]

export default routes
